// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./fonts/Inter-Regular.woff2";
import ___CSS_LOADER_URL_IMPORT_1___ from "./fonts/Inter-Black.woff2";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: 'Inter';\n  font-style:  normal;\n  font-weight: 400;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\");\n}\n\n@font-face {\n  font-family: 'Inter';\n  font-style:  normal;\n  font-weight: 900;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\");\n}\n", "",{"version":3,"sources":["webpack://src/vendor/fonts.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,gBAAgB;EAChB,4DAAuD;AACzD;;AAEA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,gBAAgB;EAChB,4DAAqD;AACvD","sourcesContent":["@font-face {\n  font-family: 'Inter';\n  font-style:  normal;\n  font-weight: 400;\n  src: url(\"./fonts/Inter-Regular.woff2\") format(\"woff2\");\n}\n\n@font-face {\n  font-family: 'Inter';\n  font-style:  normal;\n  font-weight: 900;\n  src: url(\"./fonts/Inter-Black.woff2\") format(\"woff2\");\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
