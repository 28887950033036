// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!./__description/profile__description.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!./__add-button/profile__add-button.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!./__edit-button/profile__edit-button.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!./__info/profile__info.css";
import ___CSS_LOADER_AT_RULE_IMPORT_4___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!./__title/profile__title.css";
import ___CSS_LOADER_AT_RULE_IMPORT_5___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!./__image/profile__image.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile {\n  font-family: 'Inter', Arial, sans-serif;\n  color: #fff;\n  display: flex;\n  align-items: center;\n  padding: 36px 0;\n  /*Если подгонять под ПЭПЭ, то тут padding: 40px 0; Проявите лоялити*/\n}\n\n@media screen and (max-width: 480px) {\n  .profile {\n    padding: 43px 0;\n    flex-direction: column;\n    text-align: center;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/blocks/profile/profile.css"],"names":[],"mappings":"AAOA;EACE,uCAAuC;EACvC,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,oEAAoE;AACtE;;AAEA;EACE;IACE,eAAe;IACf,sBAAsB;IACtB,kBAAkB;EACpB;AACF","sourcesContent":["@import url('./__description/profile__description.css');\n@import url('./__add-button/profile__add-button.css');\n@import url('./__edit-button/profile__edit-button.css');\n@import url('./__info/profile__info.css');\n@import url('./__title/profile__title.css');\n@import url('./__image/profile__image.css');\n\n.profile {\n  font-family: 'Inter', Arial, sans-serif;\n  color: #fff;\n  display: flex;\n  align-items: center;\n  padding: 36px 0;\n  /*Если подгонять под ПЭПЭ, то тут padding: 40px 0; Проявите лоялити*/\n}\n\n@media screen and (max-width: 480px) {\n  .profile {\n    padding: 43px 0;\n    flex-direction: column;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
