// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!./__description/card__description.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!./__image/card__image.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!./__like-button/card__like-button.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!./__like-button/_is-active/card__like-button_is-active.css";
import ___CSS_LOADER_AT_RULE_IMPORT_4___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!./__delete-button/card__delete-button.css";
import ___CSS_LOADER_AT_RULE_IMPORT_5___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!./__delete-button/_hidden/card__delete-button_hidden.css";
import ___CSS_LOADER_AT_RULE_IMPORT_6___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!./__delete-button/_visible/card__delete-button_visible.css";
import ___CSS_LOADER_AT_RULE_IMPORT_7___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!./__title/card__title.css";
import ___CSS_LOADER_AT_RULE_IMPORT_8___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!./__like-count/card__like-count.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_6___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_7___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_8___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".card {\n  font-family: 'Inter', Arial, sans-serif;\n  border-radius: 10px;\n  position: relative;\n  overflow: hidden;\n}\n", "",{"version":3,"sources":["webpack://src/blocks/card/card.css"],"names":[],"mappings":"AAUA;EACE,uCAAuC;EAGvC,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":["@import url('./__description/card__description.css');\n@import url('./__image/card__image.css');\n@import url('./__like-button/card__like-button.css');\n@import url('./__like-button/_is-active/card__like-button_is-active.css');\n@import url('./__delete-button/card__delete-button.css');\n@import url('./__delete-button/_hidden/card__delete-button_hidden.css');\n@import url('./__delete-button/_visible/card__delete-button_visible.css');\n@import url('./__title/card__title.css');\n@import url('./__like-count/card__like-count.css');\n\n.card {\n  font-family: 'Inter', Arial, sans-serif;\n  -webkit-border-radius: 10px;\n  -moz-border-radius: 10px;\n  border-radius: 10px;\n  position: relative;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
