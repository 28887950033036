// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!./__title/auth-form__title.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!./__form/auth-form__form.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!./__input/auth-form__input.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!./__textfield/auth-form__textfield.css";
import ___CSS_LOADER_AT_RULE_IMPORT_4___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!./__button/auth-form__button.css";
import ___CSS_LOADER_AT_RULE_IMPORT_5___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!./__text/auth-form__text.css";
import ___CSS_LOADER_AT_RULE_IMPORT_6___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!./__link/auth-form__link.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".auth-form {\n  flex-grow: 1;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}", "",{"version":3,"sources":["webpack://src/blocks/auth-form/auth-form.css"],"names":[],"mappings":"AAQA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB","sourcesContent":["@import url('./__title/auth-form__title.css');\n@import url('./__form/auth-form__form.css');\n@import url('./__input/auth-form__input.css');\n@import url('./__textfield/auth-form__textfield.css');\n@import url('./__button/auth-form__button.css');\n@import url('./__text/auth-form__text.css');\n@import url('./__link/auth-form__link.css');\n\n.auth-form {\n  flex-grow: 1;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
