// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!./__list/places__list.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!./__item/places__item.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".places {\n  font-family: 'Inter', Arial, sans-serif;\n  padding: 15px 0 36px;\n}\n\n@media screen and (max-width: 568px) {\n  .places {\n    padding: 15px 0 18px;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/blocks/places/places.css"],"names":[],"mappings":"AAGA;EACE,uCAAuC;EACvC,oBAAoB;AACtB;;AAEA;EACE;IACE,oBAAoB;EACtB;AACF","sourcesContent":["@import url(./__list/places__list.css);\n@import url(./__item/places__item.css);\n\n.places {\n  font-family: 'Inter', Arial, sans-serif;\n  padding: 15px 0 36px;\n}\n\n@media screen and (max-width: 568px) {\n  .places {\n    padding: 15px 0 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
