// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../images/close.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".popup__close {\n  width: 35px;\n  height: 35px;\n  background: transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center no-repeat;\n  /*Шортхэнды пора бы знать, хотя бы в можно лучше*/\n  background-size: 35px 35px;\n  /*Можно марджином тут выровнять под ПЭПЭ, но тут оставил AS.*/\n  border: none;\n  position: absolute;\n  top: -36px;\n  right: -34px;\n  transform: rotate(90deg);\n  transition: visibility 0s, opacity 0.3s;\n  /*Транзишн пора бы уже знать*/\n  padding: 0;\n  margin: 0;\n  cursor: pointer;\n}\n\n.popup__close:hover {\n  opacity: 0.6;\n}\n", "",{"version":3,"sources":["webpack://src/blocks/popup/__close/popup__close.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,gFAAyE;EACzE,iDAAiD;EACjD,0BAA0B;EAC1B,6DAA6D;EAC7D,YAAY;EACZ,kBAAkB;EAClB,UAAU;EACV,YAAY;EAKZ,wBAAwB;EACxB,uCAAuC;EACvC,6BAA6B;EAC7B,UAAU;EACV,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".popup__close {\n  width: 35px;\n  height: 35px;\n  background: transparent url('../../../images/close.svg') center no-repeat;\n  /*Шортхэнды пора бы знать, хотя бы в можно лучше*/\n  background-size: 35px 35px;\n  /*Можно марджином тут выровнять под ПЭПЭ, но тут оставил AS.*/\n  border: none;\n  position: absolute;\n  top: -36px;\n  right: -34px;\n  -webkit-transform: rotate(90deg);\n  -moz-transform: rotate(90deg);\n  -ms-transform: rotate(90deg);\n  -o-transform: rotate(90deg);\n  transform: rotate(90deg);\n  transition: visibility 0s, opacity 0.3s;\n  /*Транзишн пора бы уже знать*/\n  padding: 0;\n  margin: 0;\n  cursor: pointer;\n}\n\n.popup__close:hover {\n  opacity: 0.6;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
