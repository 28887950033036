// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../images/add-icon.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile__add-button {\n  width: 150px;\n  height: 50px;\n  background: transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center no-repeat;\n  background-size: 22px;\n  border-radius: 2px;\n  /*Че это*/\n  border: 2px solid #fff;\n  transition: 0.3s;\n  cursor: pointer;\n  margin-left: auto;\n}\n\n.profile__add-button:hover {\n  opacity: 0.6;\n}\n\n@media screen and (max-width: 740px) {\n  .profile__add-button {\n    width: 50px;\n    height: 50px;\n  }\n}\n\n@media screen and (max-width: 480px) {\n  .profile__add-button {\n    width: 100%;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/blocks/profile/__add-button/profile__add-button.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,gFAA4E;EAC5E,qBAAqB;EAGrB,kBAAkB;EAClB,SAAS;EACT,sBAAsB;EAKtB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,WAAW;IACX,YAAY;EACd;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".profile__add-button {\n  width: 150px;\n  height: 50px;\n  background: transparent url(\"../../../images/add-icon.svg\") center no-repeat;\n  background-size: 22px;\n  -webkit-border-radius: 2px;\n  -moz-border-radius: 2px;\n  border-radius: 2px;\n  /*Че это*/\n  border: 2px solid #fff;\n  -webkit-transition: 0.3s;\n  -moz-transition: 0.3s;\n  -ms-transition: 0.3s;\n  -o-transition: 0.3s;\n  transition: 0.3s;\n  cursor: pointer;\n  margin-left: auto;\n}\n\n.profile__add-button:hover {\n  opacity: 0.6;\n}\n\n@media screen and (max-width: 740px) {\n  .profile__add-button {\n    width: 50px;\n    height: 50px;\n  }\n}\n\n@media screen and (max-width: 480px) {\n  .profile__add-button {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
