// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../images/like-inactive.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".card__like-button {\n  width: 21px;\n  height: 18px;\n  background: transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  /*Потом это будет из модификатора*/\n  background-size: 21px 18px;\n  border: none;\n  cursor: pointer;\n  padding: 0;\n  margin: 0;\n  transition: 0.3s;\n}\n\n.card__like-button:hover {\n  opacity: 0.6;\n}\n", "",{"version":3,"sources":["webpack://src/blocks/card/__like-button/card__like-button.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,yEAA0E;EAC1E,kCAAkC;EAClC,0BAA0B;EAC1B,YAAY;EACZ,eAAe;EACf,UAAU;EACV,SAAS;EAKT,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".card__like-button {\n  width: 21px;\n  height: 18px;\n  background: transparent url('../../../images/like-inactive.svg') no-repeat;\n  /*Потом это будет из модификатора*/\n  background-size: 21px 18px;\n  border: none;\n  cursor: pointer;\n  padding: 0;\n  margin: 0;\n  -webkit-transition: 0.3s;\n  -moz-transition: 0.3s;\n  -ms-transition: 0.3s;\n  -o-transition: 0.3s;\n  transition: 0.3s;\n}\n\n.card__like-button:hover {\n  opacity: 0.6;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
